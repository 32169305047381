<template>
    <div>
        <a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
            <div class="logo">
                <img src="../../assets/images/logo.jpg" alt="logo" />
            </div>
            <h5 class="font-semibold text-center">{{ $t('forgot_password.title') }}</h5>
            <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
                <a-form-item>
                    <a-input
                        v-decorator="[
                            'email',
                            {
                                rules: [
                                    { required: true, message: $t('form.email_required') },
                                    { type: 'email', message: $t('form.incorrect_email_format') },
                                ],
                            },
                        ]"
                        :placeholder="$t('form.email')"
                        autocomplete="off"
                    >
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button text-uppercase">
                        {{ $t('form.submit') }}
                    </a-button>
                </a-form-item>
            </a-form>
            <p class="font-semibold text-muted text-center">
                <router-link :to="{ path: `/sign-in/${companyCode}` }" class="font-bold text-dark">
                    {{ $t('go_back') }}
                </router-link>
            </p>
        </a-card>
    </div>
</template>

<script>
import { HTTP } from '../../config/http-common';
import { mixinToasts, mixinLoadings } from '../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings],
    components: {},
    data() {
        return {
            form: this.$form.createForm(this, { name: 'forgot-form' }),
            companyCode: this.$route.params.company_code,
        };
    },
    methods: {
        // Handles input validation after submission.
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.showLoading();
                    HTTP.post('/auth/forgot_password', {
                        ...values,
                        company_code: this.companyCode,
                    })
                        .then((res) => {
                            setTimeout(() => {
                                this.hideLoading();
                                this.showToast('success', this.$t('forgot_password.text_success'));
                            }, 500);
                        })
                        .catch((error) => {
                            this.hideLoading();
                            this.showError(error);
                        });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.card-signup {
    margin: 0 auto;
    & .logo {
        text-align: center;
        margin-bottom: 40px;
    }
}
</style>
